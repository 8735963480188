@import '../abstracts/variables';

.btn-opacity {
  &.ant-btn, &.ant-btn:active {
    background: $color-whiteOpacity50 !important;
    border: none !important;
    padding: 7px 8px;
    height: $btn-opacity-height;
    border-radius: $border-radius-btn-opacity;
  }

  &_gray {
    &.ant-btn, &.ant-btn:active {
      background: $color-grayLight !important;
      border-radius: $border-radius;
    }
  }

  &_gray-disabled {
    &.ant-btn, &.ant-btn:active {
      background: $color-grayLightest !important;
      border-radius: $border-radius;

      path {
        stroke: white;
      }
    }
  }

  &_orange {
    &.ant-btn, &.ant-btn:active {
      background: $color-main-orange !important;
      color: white;

      path {
        fill: white;
      }
    }
  }

  &_sm {
    &.ant-btn, &.ant-btn:active {
        padding: 4px 5px;
      }
  }

  &_left {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }

  &_right {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }
}
