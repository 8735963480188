@import '../abstracts/variables';

$anchor-link-active-color: #43281E;

.anchor {
  margin: 0;
  background: $color-grayLight;

  .ant-anchor-ink {
    display: none !important;
  }

  .ant-anchor {
    display: flex;
    max-width: $content-desktop-width;
    margin: 0 auto;
    padding: $spacer-4 $spacer-7;
  }

  &__link {
    background: white;
    border-radius: $border-radius;
    padding: 0;

    a {
      padding: $spacer-4;
    }

    & + & {
      margin-left: $spacer-5;
    }

    &:last-child {
      margin-right: $spacer-5;
    }

    a {
      color: $color-text-darkCherry;
      text-decoration: none;
      font-size: $fontSize-xxxs !important;
      font-weight: bold;
    }

    &.ant-anchor-link-active {
      background: $color-main-y;

      a {
        color: $anchor-link-active-color
      }
    }
  }
}


