@import '../abstracts/variables';

$count-min-width: 42px;

.menu-details {
  color: $color-text;
  margin-bottom: $spacer-7;

  &__header {
    font-weight: 700;
    font-size: $fontSize-xs;
    line-height: $fontSize-sm;

    & + .menu-details__description {
      margin-top: $spacer-4;
    }
  }

  &__description {
    font-size: $fontSize-xxxs;
    line-height: $fontSize-sm;
    opacity: $opacity-text;
  }

  &__image-wrapper {
    margin-left: $spacer-4;
    width: $menu-item-img-width;
  }

  &__image {
    width: $menu-item-img-width;
    max-height: $menu-item-img-width;
  }

  &__count {
    min-width: $count-min-width;
    font-size: $fontSize-xs;
    font-weight: 700;
    display: flex;
    justify-content: center;
    align-items: center;
    height: $btn-opacity-height;
  }
}
