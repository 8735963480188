@import '../abstracts/variables';

html {
  --antd-wave-shadow-color: $color-main-orange;
}

// form label
.ant-form-item-label {
  padding-bottom: $spacer-2 !important;

  label {
    font-size: $fontSize-xxxs;
    line-height: $fontSize-sm;
    height: auto;
  }
}

// input icons
.ant-input-suffix .anticon {
  color: $color-main-orange;
}

// input
.ant-input,
.ant-input:hover,
.ant-input:active,
.ant-input-affix-wrapper,
.ant-input-affix-wrapper:hover,
.ant-input-affix-wrapper:active,
.ant-input-affix-wrapper:focus {
  border: none;
  border-radius: $border-radius;
  background: $color-input-bg;
  padding: $spacer-3 $spacer-5;
  box-shadow: none;

  &:disabled,
  &[disabled],
  &.ant-input-affix-wrapper-disabled {
    opacity: $opacity-controls;
  }
}
// selector
.ant-select,
.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border: none;
  border-radius: $border-radius;
  background: $color-input-bg;
  box-shadow: none;

  &:disabled,
  &[disabled],
  &.ant-select-selector-affix-wrapper-disabled {
    opacity: $opacity-controls;
  }
}

.ant-form-item-has-error {
  & .ant-input,
  .ant-input:hover,
  .ant-input:focus {
    background: $color-input-bg;
    box-shadow: none;
  }
}

/* Change Autocomplete styles in Chrome*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
.ant-input-affix-wrapper:-webkit-autofill,
.ant-input-affix-wrapper:-webkit-autofill:hover,
.ant-input-affix-wrapper:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: none;
  -webkit-text-fill-color: $color-text;
  -webkit-box-shadow: 0 0 0px 1000px $color-input-bg inset;
  transition: background-color 5000s ease-in-out 0s;
}

// checkbox
.ant-checkbox-checked .ant-checkbox-inner {
  background: $color-main-orange;
  border-color: $color-main-orange !important;
}
.ant-checkbox-input,
.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner,
.ant-checkbox-checked::after {
  border-color: $color-main-orange;
}

.ant-checkbox-disabled {
  opacity: $opacity-controls;
}

// spin
.ant-spin-dot-item {
  background-color: $color-main-orange;
}

.ant-picker {
  border: none;
  border-radius: $border-radius;
  background: $color-input-bg;
  padding: $spacer-3 $spacer-5;
  box-shadow: none;

  &:disabled,
  &[disabled],
  &.ant-input-affix-wrapper-disabled {
    opacity: $opacity-controls;
  }
}

.ant-spin {
  width: 100%;
  min-height: 27px; //fix incorrect spin position on empty list
}

.ant-select-clear {
  width: 40px;
  height: 100%;
  top: 0;
  right: 0;
  margin-top: 0;
  background: $color-input-bg;
  align-items: center;
  display: flex;
  justify-content: center;
  border-radius: $border-radius;
}

.list-without-empty {
  .ant-list-empty-text {
    display: none !important;
  }
}

.ant-radio-wrapper {
  display: flex;
  align-items: center;
}

.ant-radio-inner {
  border-color: $color-text;
  width: 24px;
  height: 24px;
}

.ant-radio-inner::after {
  visibility: hidden;
}

.ant-radio-checked .ant-radio-inner,
.ant-radio-checked::after {
  border-color: $color-main-orange !important;
  border-width: 6px !important;
  box-shadow: none;
}

.ant-radio-wrapper:hover .ant-radio,
.ant-radio:hover .ant-radio-inner,
.ant-radio-input:focus + .ant-radio-inner,
.ant-radio-input:active + .ant-radio-inner {
  border-color: $color-text;
  border-width: 1px;
  box-shadow: none;
}

.ant-radio-checked::after {
  animation: none;
  border: none;
}
