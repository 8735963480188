@import '../abstracts/variables';

.btn {
  &.ant-btn,
  &.ant-btn:active {
    height: $button-default;
    font-weight: bold;
    font-size: $fontSize-sm;
    border-radius: $border-radius;
    border-color: $color-main-orange !important;
    color: $color-main-orange;
  }

  &.btn_red,
  &.btn_red:active {
    border-color: $special-red !important;
    color: $special-red;
  }

  &.ant-btn-primary {
    background: $color-main-orange !important;
    color: white !important;
  }

  &:disabled,
  &[disabled] {
    opacity: $opacity-controls;
  }

  &_link {
    padding-left: 2px;
    padding-right: 2px;

    &[disabled] {
      opacity: 0.5;
    }

    span {
      color: $color-main-orange;
      text-decoration: underline;
      font-size: $fontSize-xxxs !important;
      font-weight: bold;
    }
  }

  &_link_sm {
    span {
      font-size: $fontSize-xs !important;
    }
  }

  &_height_responsive {
    height: auto;

    span {
      white-space: normal;
    }
  }
}
